import { setLoaders, setLogged } from "../../../redux-toolkit";

import { LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";

export default function Logout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <Button
      type="text"
      data-testid="logout-button"
      onClick={() => {
        dispatch(setLoaders({ signLoad: false }));
        dispatch(setLogged(false));
        navigate("/");
        sessionStorage.clear();
      }}
      style={{ width: 64, height: 64 }}
      icon={<LogoutOutlined className="menu-icon" />}
    />
  );
}
